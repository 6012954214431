import { Auth0Provider } from "@auth0/auth0-react";
import "choices.js/public/assets/styles/choices.min.css";
import "flatpickr/dist/flatpickr.css";
import React, { lazy, Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import "shepherd.js/dist/css/shepherd.css";
import fetchUrl from "../src/api";
import { useAuth } from "../src/utils/AuthContext";
import { MASTER_CSS } from "../src/utils/constant";
import "./assets/custom/scss/custom.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/customizer.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/hope-ui.scss";
import "./assets/scss/pro.scss";
import "./assets/scss/rtl.scss";
import Loader from "./components/loader/Loader";
import OverlayLoader from "./components/loader/Spinner";
import { setSetting } from "./store/setting/actions";
import MasterCss from "./views/dashboard/components/MasterCss/MasterCss";
import "./views/dashboard/styles/app.css";

import allRoutes from "./routes"; // Import the combined routes

import { fetcAllhDashboardWidget } from "./store/Dashboard/reducers";
import { fetchDataSource } from "./store/Datasource/reducers";
import { fetchQuerysets } from "./store/quersets/reducers";

// Lazy load the components
const SignIn = React.lazy(() => import("./views/dashboard/auth/sign-in"));
const SignUp = React.lazy(() => import("./views/dashboard/auth/sign-up"));
const ForgotPassword = React.lazy(() =>
  import("./views/dashboard/auth/forgot-password")
);
const CreatePassword = React.lazy(() =>
  import("./views/dashboard/auth/create-password")
);
const PrivateRoute = React.lazy(() =>
  import("./views/dashboard/auth/privateroute")
);
const Index = React.lazy(() => import("./views"));
const Home = React.lazy(() => import("./views/dashboard/components/home"));
const Profile = React.lazy(() =>
  import("./views/dashboard/components/Profile/Profile")
);
const ResetPassword = React.lazy(() =>
  import("./views/dashboard/components/Profile/ResetPassword")
);
const Groups = React.lazy(() =>
  import("./views/dashboard/components/Profile/Groups")
);
const InviteUser = React.lazy(() =>
  import("./views/dashboard/components/Profile/InviteUser")
);
const Callback = React.lazy(() => import("./views/dashboard/auth/callback"));
const MasterStyling = React.lazy(() =>
  import("./views/dashboard/components/MasterStyling")
);
const SubCustomers = React.lazy(() =>
  import("./views/dashboard/components/SubscriptionCustomers/SubCustomers")
);
const SubSingleCustomerCouponList = React.lazy(() =>
  import(
    "./views/dashboard/components/SubscriptionCustomers/SubSingleCustomerCouponList"
  )
);
const ChangeSubscriptionPlan = React.lazy(() =>
  import(
    "./views/dashboard/components/SubscriptionCustomers/ChangeSubscriptionPlan"
  )
);
const SubCustomerInformation = React.lazy(() =>
  import(
    "./views/dashboard/components/SubscriptionCustomers/SubCustomerInformation"
  )
);
const SubPaymentGateway = React.lazy(() =>
  import(
    "./views/dashboard/components/SubscriptionPaymentGateway/SubPaymentGateway"
  )
);
const StripeSuccessIntegration = React.lazy(() =>
  import("./views/dashboard/components/Stripe/SuccessIntegration")
);
const SubEmail = React.lazy(() =>
  import("./views/dashboard/components/SubscriptionEmail/SubEmail")
);
const SubNewSubscription = React.lazy(() =>
  import("./views/dashboard/components/SubscriptionEmail/SubNewSubscription")
);
const SubPreview = React.lazy(() =>
  import("./views/dashboard/components/SubscriptionEmail/SubPreview")
);
const SubCoupons = React.lazy(() =>
  import("./views/dashboard/components/SubscriptionCoupons/SubCoupons")
);
const SubNewCoupons = React.lazy(() =>
  import("./views/dashboard/components/SubscriptionCoupons/SubNewCoupons")
);
const SubRedeemCoupons = React.lazy(() =>
  import("./views/dashboard/components/SubscriptionCoupons/SubRedeemCoupons")
);
const SubInvoice = React.lazy(() =>
  import("./views/dashboard/components/SubscriptionInvoice/SubInvoice")
);
const DataSources = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/DataSources")
);
const PreviewDataSource = React.lazy(() =>
  import(
    "./views/dashboard/components/DBConnection/PreviewDataSource/PreviewDataSource"
  )
);
const Connectstream = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/Connectstream")
);
const SubSettings = React.lazy(() =>
  import("./views/dashboard/components/SubscriptionSettings/SubSettings")
);
const TableDescription = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/TableDescription")
);
const DataSourceTables = React.lazy(() =>
  import(
    "./views/dashboard/components/DBConnection/DataSourceTables/DataSourceTables"
  )
);
const DashboardviewList = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/DashboardviewList")
);
const DashboardChartDesign = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/DashboardChartDesign")
);
const AnalyticsSubscriptions = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/AnalyticsSubscriptions")
);
const AnalyticsQuerysets = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/AnalyticsQuerysets")
);
const PreviewQuerysets = React.lazy(() =>
  import(
    "./views/dashboard/components/DBConnection/PreviewQuerySets/PreviewQuerySets"
  )
);
const QuerySetList = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/QuerySetList")
);
const Reports = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/Reports")
);
const AnalyticsSetting = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/AnalyticsSetting")
);
const AnalyticsChartDashBoard = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/AnalyticsChartDashBoard")
);
const AnalyticsWelcomeDashBoard = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/AnalyticsWelcomeDashBoard")
);
const AnalyticsTableSync = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/AnalyticsTableSync")
);
const AnalyticsSaleChart = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/AnalyticsSaleChart")
);
const CreateProfile = React.lazy(() =>
  import("./views/dashboard/components/ProfilePages/CreateProfile")
);
const ProfilePreview = React.lazy(() =>
  import("./views/dashboard/components/ProfilePages/ProfilePreview")
);
const ProfileForm = React.lazy(() =>
  import("./views/dashboard/components/ProfilePages/ProfileForm")
);
const ClientProfile = React.lazy(() =>
  import("./views/dashboard/components/ProfilePages/ClientProfile")
);
const PaymentDashBoard = React.lazy(() =>
  import("./views/dashboard/components/PaymentCheckout/PaymentDashBoard")
);
const PaymentCheckout = React.lazy(() =>
  import("./views/dashboard/components/PaymentCheckout/PaymentCheckout")
);
const VerifyEmail = React.lazy(() =>
  import("./views/dashboard/auth/verify-email")
);
const DBConnect = React.lazy(() =>
  import("./views/dashboard/components/DBConnection/DBConnect")
);
const RecurringPayment = React.lazy(() =>
  import("./views/dashboard/components/PaymentCheckout/RecurringPayment")
);

const Navigation = lazy(() => import("./views/maps/pages/Navigation"));
const Geofence = lazy(() => import("./views/maps/pages/Geofence"));
const MapsSetup = lazy(() => import("./views/maps/pages/MapsSetup"));
const AISubscriptionPlans = lazy(() =>
  import("./views/dashboard/components/AISubscription/AISubscriptionPlans")
);
const AISubscriptionPlanPreview = lazy(() =>
  import(
    "./views/dashboard/components/AISubscription/AISubscriptionPlanPreview"
  )
);
const AISubscription = lazy(() =>
  import("./views/dashboard/components/AISubscription/AISubscription")
);
const AISubscriptionPayment = lazy(() =>
  import("./views/dashboard/components/AISubscription/AISubscriptionPayment")
);
const ProfileDashBoard = lazy(() =>
  import("./views/dashboard/components/ProfilePages/ProfileDashBoard")
);

const AuthExternalDashBoard = lazy(() =>
  import("./views/dashboard/components/AuthExternal/AuthExternalDashBoard")
);
const AuthSignup = lazy(() =>
  import("./views/dashboard/components/AuthExternal/AuthSignup")
);
const AuthSignin = lazy(() =>
  import("./views/dashboard/components/AuthExternal/AuthSignin")
);
const ForgetPassword = lazy(() =>
  import("./views/dashboard/components/AuthExternal/ForgetPassword")
);
const AuthResetPassword = lazy(() =>
  import("./views/dashboard/components/AuthExternal/AuthResetPassword")
);
const AuthSetting = lazy(() =>
  import("./views/dashboard/components/AuthExternal/AuthSetting")
);
const AuthVerification = lazy(() =>
  import("./views/dashboard/components/AuthExternal/AuthVerification")
);
const AIChatbot = lazy(() =>
  import("./views/dashboard/components/AIChatbot/AIChatbot")
);
const AIChatbotSettings = lazy(() =>
  import("./views/dashboard/components/AIChatbot/AIChatbotSettings")
);
const ConnectionDashBoard = lazy(() =>
  import("./views/dashboard/components/DBConnection/ConnectionDashBoard")
);

const AdminPanel = lazy(() =>
  import("./views/dashboard/components/DBConnection/AdminPanel")
);

const Calendar = lazy(() => import("./views/calendar/pages/Calendar"));

const ExternalCalendar = lazy(() =>
  import("./views/calendar/pages/CalendarExternal")
);

const CalendarSetting = lazy(() =>
  import("./views/calendar/pages/CalendarSetting")
);

const ExternalCalendarConfirmation = lazy(() =>
  import("./views/calendar/pages/ExternalCalendarConfirmation")
);

function App() {
  const dispatch = useDispatch();
  dispatch(setSetting());
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      getUserCss();
    }
  }, [isAuthenticated]);

  const getUserCss = async () => {
    try {
      const url = MASTER_CSS.GET_CSS;
      const response = await fetchUrl({ type: "GET", url });

      if (response?.data?.file) {
        const cssText = await fetch(response.data.file).then((res) =>
          res.text()
        );
        applyCSS(cssText);
      }
    } catch (error) {}
  };

  const applyCSS = (cssContent) => {
    const style = document.createElement("style");
    style.textContent = cssContent;
    document.head.appendChild(style);
  };

  const wlocation = window.location.pathname;
  if (typeof wlocation === "string" && wlocation.startsWith("/analytics")) {
    dispatch(fetchDataSource());
    dispatch(fetchQuerysets());
    dispatch(fetcAllhDashboardWidget());
  }

  return (
    <div className="App">
      <OverlayLoader />
      <Toaster position="top-right" />
      <Auth0Provider
        domain="dev-4086qximvo3h5dm2.us.auth0.com"
        clientId="kw2KJwhGH5ocoNG7mpa4YzzoVjP1c544"
        redirectUri={process.env.REACT_APP_AUTH_REDIRECT_URL}
      >
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="*" element={<Index />} />
              <Route path="/" element={<Home />} />
              <Route path="/my-account/profile" element={<Profile />} />
              <Route path="/my-account/users" element={<Groups />} />
              <Route
                path="/my-account/groups/invite-user"
                element={<InviteUser />}
              />
              <Route
                path="my-account/change-password"
                element={<ResetPassword />}
              />

              {/* Master Styling */}
              <Route path="/master-styling" element={<MasterStyling />} />

              {/* Chatbot */}
              <Route path="/ai-chatbot/content" element={<AIChatbot />} />
              <Route
                path="/ai-chatbot/settings"
                element={<AIChatbotSettings />}
              />

              {/* Maps */}
              <Route path="/maps/MapsSetup" element={<MapsSetup />} />
              <Route
                path="/maps/Navigation"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Navigation />
                  </Suspense>
                }
              />
              <Route
                path="/maps/Geofence"
                element={
                  <Suspense fallback={<div>Loading...</div>}>
                    <Geofence />
                  </Suspense>
                }
              />

              {/* Subscription */}
              <Route path="/ai-subscription" element={<AISubscription />} />
              <Route
                path="/ai-subscription/ai-subscription-plans"
                element={<AISubscriptionPlans />}
              />
              <Route
                path="/ai-subscription/ai-subscription-plans/preview"
                element={<AISubscriptionPlanPreview />}
              />
              <Route
                path="/ai-subscription/ai-subscription-customers"
                element={<SubCustomers />}
              />
              <Route
                path="/ai-subscription/ai-subscription-customers/coupons/:selectedPlanID/:currentPlanId"
                element={<SubSingleCustomerCouponList />}
              />
              <Route
                path="/ai-subscription/ai-subscription-customers/change-plan/:subscriptionId"
                element={<ChangeSubscriptionPlan />}
              />
              <Route
                path="/ai-subscription/ai-subscription-customers/customers/:customersId"
                element={<SubCustomerInformation />}
              />
              <Route
                path="/ai-subscription/payment-gateway"
                element={<SubPaymentGateway />}
              />
              <Route
                path="/stripe/success-integration"
                element={<StripeSuccessIntegration />}
              />
              <Route path="/ai-subscription/email" element={<SubEmail />} />
              <Route
                path="/ai-subscription/email/new-subscription"
                element={<SubNewSubscription />}
              />
              <Route
                path="/ai-subscription/email/preview"
                element={<SubPreview />}
              />
              <Route
                path="/ai-subscription/ai-subscription-payment"
                element={<AISubscriptionPayment />}
              />
              <Route path="/ai-subscription/coupons" element={<SubCoupons />} />
              <Route
                path="/ai-subscription/redeemcoupons"
                element={<SubRedeemCoupons />}
              />
              <Route
                path="/ai-subscription/newcoupons"
                element={<SubNewCoupons />}
              />
              <Route path="/ai-subscription/invoice" element={<SubInvoice />} />
              <Route
                path="/ai-subscription/settings"
                element={<SubSettings />}
              />

              {/* Analytics */}
              <Route path="/analytics" element={<ConnectionDashBoard />}>
                {/* <Route path="" element={<Navigate to="/analytics/datasources" />} /> */}
                <Route path="dataSources" element={<DataSources />} />
                <Route
                  path="preview-data-source"
                  element={<PreviewDataSource />}
                />
                <Route path="connectstream" element={<Connectstream />} />
                <Route path="tablesync" element={<AnalyticsTableSync />} />
                <Route
                  path="dbTableDescription"
                  element={<TableDescription />}
                />
                <Route
                  path="data-source-tables"
                  element={<DataSourceTables />}
                />

                <Route path="dbconnect" element={<DBConnect />} />
                <Route path="dashboard" element={<DashboardviewList />} />
                <Route
                  path="edit-dashboard"
                  element={<DashboardChartDesign />}
                />
                <Route path="analyticsSetting" element={<AnalyticsSetting />} />
                <Route path="queryset" element={<QuerySetList />} />
                <Route
                  path="preview-query-set"
                  element={<PreviewQuerysets />}
                />
                <Route path="querysets" element={<AnalyticsQuerysets />} />
                <Route path="adminpanel" element={<AdminPanel />} />
                <Route path="reports" element={<Reports />} />
              </Route>

              <Route
                path="analytics/chart-dashBoard"
                element={<AnalyticsChartDashBoard />}
              />
              <Route
                path="analytics/chart-dashboard-design"
                element={<AnalyticsSaleChart />}
              />
              <Route
                path="analytics/subscriptions"
                element={<AnalyticsSubscriptions />}
              />

              {/* Profile */}
              <Route path="profileDashboard" element={<ProfileDashBoard />}>
                <Route path="createProfile" element={<CreateProfile />} />
                <Route path="clientProfile" element={<ClientProfile />} />
                <Route path="setting" element={"Setting"} />
              </Route>
              <Route
                path="/profileDashboard/profileView"
                element={<ProfilePreview />}
              />
              <Route
                path="/profileDashboard/profile-form"
                element={<ProfileForm />}
              />

              {/* Calendar */}
              <Route path="/ai-calendar/internal" element={<Calendar />} />
              <Route
                path="/ai-calendar/external/confirmation"
                element={<ExternalCalendarConfirmation />}
              />
              <Route
                path="/ai-calendar/external"
                element={<ExternalCalendar />}
              />
              <Route
                path="/ai-calendar/settings"
                element={<CalendarSetting />}
              />
            </Route>

            <Route
              path="analytics/chart-dashBoard"
              element={<AnalyticsChartDashBoard />}
            />
            <Route
              path="analytics/chart-dashboard-design"
              element={<AnalyticsSaleChart />}
            />
            <Route
              path="analytics/subscriptions"
              element={<AnalyticsSubscriptions />}
            />

            <Route
              path="/profileDashboard/profileView"
              element={<ProfilePreview />}
            />
            <Route
              path="/profileDashboard/profile-form"
              element={<ProfileForm />}
            />

            {/* Auth-External */}
            <Route
              path="auth/external/*"
              element={
                <Suspense fallback={<Loader />}>
                  <AuthExternalDashBoard />
                </Suspense>
              }
            >
              <Route
                path="signup"
                element={
                  <Suspense fallback={<Loader />}>
                    <AuthSignup />
                  </Suspense>
                }
              />
              <Route
                path="signin"
                element={
                  <Suspense fallback={<Loader />}>
                    <AuthSignin />
                  </Suspense>
                }
              />
              <Route
                path="forgotPassword"
                element={
                  <Suspense fallback={<Loader />}>
                    <ForgetPassword />
                  </Suspense>
                }
              />
              <Route
                path="resetPassword"
                element={
                  <Suspense fallback={<Loader />}>
                    <AuthResetPassword />
                  </Suspense>
                }
              />
              <Route
                path="settings"
                element={
                  <Suspense fallback={<Loader />}>
                    <AuthSetting />
                  </Suspense>
                }
              />
              <Route
                path="verification/:from"
                element={
                  <Suspense fallback={<Loader />}>
                    <AuthVerification />
                  </Suspense>
                }
              />
            </Route>

            {/* Payment checkout */}
            <Route path="paymentDashBoard" element={<PaymentDashBoard />}>
              <Route path="paymentCheckout" element={<PaymentCheckout />} />
              <Route path="recurringPayment" element={<RecurringPayment />} />
              <Route path="settings" element={"Settings"} />
            </Route>

            {/* Iterate over the module routes */}
            {allRoutes.map((route) => {
              const routeKey = `${route.path}-${route.element.type.name}`;
              if (route.children) {
                return (
                  <Route
                    key={routeKey}
                    path={route.path}
                    element={route.element}
                  >
                    {route.children.map((child) => {
                      const childKey = `${child.path}-${child.element.type.name}`;
                      return (
                        <Route
                          key={childKey}
                          path={child.path}
                          element={child.element}
                        />
                      );
                    })}
                  </Route>
                );
              }
              return (
                <Route
                  key={routeKey}
                  path={route.path}
                  element={route.element}
                />
              );
            })}

            {/* Master Styling */}
            <Route path="/master-css" element={<MasterCss />} />

            {/* Auth routes */}
            <Route
              path="/auth/sign-in/"
              element={<SignIn isDisplay={true} />}
            />
            <Route path="/auth/sign-up" element={<SignUp isDisplay={true} />} />
            <Route path="/auth/callback" element={<Callback />} />
            <Route path="/auth/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/verify-email" element={<VerifyEmail />} />

            <Route
              path="/auth/set-password/:hash/:token"
              element={<CreatePassword />}
            />
          </Routes>
        </Suspense>
      </Auth0Provider>
    </div>
  );
}

export default App;
