import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import fetchUrl from "../../api/index";
import { DATA_SOURCE_APIS, QUERY_SETS_API } from "../../utils/constant";
import { hideLoader, showLoader } from "../loader/reducer";

let configObj = {
  baseUrl: process.env.REACT_APP_URL,
  contentType: "application/json",
};
export const fetchQuerysets = createAsyncThunk(
  "querysets/fetch",
  async (_, { dispatch }) => {
    const type = "get";
    const url = QUERY_SETS_API.get;
    const urlDatSource = DATA_SOURCE_APIS.get;
    try {
      dispatch(showLoader());
      const response = await fetchUrl({ type, url, config: configObj });

      if (response.status === 200) {
        const resp = await fetchUrl({
          type,
          url: urlDatSource,
          config: configObj,
        });

        const updatedQuerysets = response?.data?.results?.map((queryset) => {
          const matchedDataSource = resp?.data?.results.find(
            (dataSource) => dataSource.id === queryset.datasource
          );

          return {
            ...queryset,
            dataSourceBrand: matchedDataSource
              ? matchedDataSource.database_brand
              : "Unknown",
          };
        });

        response.data.results = updatedQuerysets;

        return response.data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(hideLoader());
    }
  }
);
export const addQuerySets = createAsyncThunk(
  "querysets/add",
  async (data, { dispatch }) => {
    const type = "post";
    const url = QUERY_SETS_API.add;
    try {
      dispatch(showLoader());

      const response = await fetchUrl({ type, url, data, config: configObj });
      if (response?.status === 201) {
        toast.success("Query set created successfully.");
        dispatch(fetchQuerysets());
        return response.data;
      } else {
        toast.error("Please check your credentials and try again.");
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(hideLoader());
    }
  }
);
export const executeQuerySets = createAsyncThunk(
  "querysets/execute",
  async (data) => {
    const type = "post";
    const url = `/query-set/${data.query_set_id}/`;

    try {
      const response = await fetchUrl({
        type,
        url,
        config: configObj,
      });
      if (response?.status === 200) {
        if (response.data.status != 500) {
          return response?.data;
        } else {
          toast.error("Query Execute failed Please select valid! query set.");
          return response?.data;
        }
      } else {
        if (response) {
          toast.error(response?.data?.error);
        }
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);
export const deleteQuerySets = createAsyncThunk(
  "querysets/delete",
  async (data, { dispatch }) => {
    const type = "put";
    const url = `/query-set/${data.query_set_id}/`;
    try {
      dispatch(showLoader());
      const response = await fetchUrl({
        type,
        url,
        data: data.body,
        config: configObj,
      });
      await dispatch(fetchQuerysets());
      if (response.status == 204) {
        toast.success("Query Delete successfully!");
        return data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(hideLoader());
    }
  }
);
export const updateQuerySets = createAsyncThunk(
  "querysets/update",
  async (data, { dispatch }) => {
    let { excutequery = false } = data;
    const type = "put";
    const url = `/query-set/${data.query_set_id}/`;
    try {
      const response = await fetchUrl({
        type,
        url,
        data: data.body,
        config: configObj,
      });
      if (response.status == 204) {
        if (data && excutequery) {
          dispatch(
            executeQuerySets({
              datasource_id: data.datasource_id,
              query_set_id: data.query_set_id,
            })
          );
        } else {
          toast.success("Query Saved successfully!");
        }
        return data;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);
export const aitextQuerySets = createAsyncThunk(
  "querysets/aitext",
  async (data) => {
    const type = "post";
    const url = `/query-set/add/smart/`;
    try {
      const response = await fetchUrl({
        type,
        url,
        data: data.body,
        config: configObj,
      });
      if (response) {
        return response;
      } else {
        toast.error(response?.data?.error);
        throw new Error(response);
      }
    } catch (error) {
      throw error;
    }
  }
);
// Utility functions for setting state
const setLoading = (state) => {
  state.status = "loading";
  state.statuscode = 2;
  state.queryexecute = [];
  state.aiquerystr = [];
};
const setSucceeded = (state) => {
  state.status = "succeeded";
  state.statuscode = 1;
};
const setFailed = (state, action) => {
  state.status = "failed";
  state.statuscode = 0;
  state.error = action.error.message;
};

// Define the initial state
const initialState = {
  data: [],
  status: "idle",
  statuscode: 0,
  queryexecute: [],
  aiquerystr: "",
  aiissuesstr: "",
  querysettitle: "",
  aiissueslist: [],
  error: null,
};

const querysetsSlice = createSlice({
  name: "querysets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Query Sets
      .addCase(fetchQuerysets.pending, setLoading)
      .addCase(fetchQuerysets.fulfilled, (state, action) => {
        setSucceeded(state);
        state.data = action.payload;
        state.queryexecute = [];
      })
      .addCase(fetchQuerysets.rejected, setFailed)
      // Add Query Sets
      .addCase(addQuerySets.pending, setLoading)
      .addCase(addQuerySets.fulfilled, (state, action) => {
        state.querysettitle = action.payload.name;
        setSucceeded(state);
      })
      .addCase(addQuerySets.rejected, setFailed)
      // Delete Query Sets
      .addCase(deleteQuerySets.pending, setLoading)
      .addCase(deleteQuerySets.fulfilled, (state, action) => {
        setSucceeded(state);
        state.data.results = state.data?.results.filter(
          (item) => item.id !== action.payload.query_set_id
        );
      })
      .addCase(deleteQuerySets.rejected, setFailed)
      // Update Query Sets
      .addCase(updateQuerySets.pending, setLoading)
      .addCase(updateQuerySets.fulfilled, (state, action) => {
        setSucceeded(state);
        let findIndex = state.data.results.findIndex(
          (item) => item.id === action.payload.query_set_id
        );
        if (findIndex != -1) {
          // state.data.results[findIndex] = action.payload.body;
        }
      })
      .addCase(updateQuerySets.rejected, setFailed)
      // Execute Query Sets
      .addCase(executeQuerySets.pending, setLoading)
      .addCase(executeQuerySets.fulfilled, (state, action) => {
        setSucceeded(state);
        state.queryexecute = action.payload;
      })
      .addCase(executeQuerySets.rejected, setFailed)
      // AI Text Rewsponse Query Sets
      .addCase(aitextQuerySets.pending, setLoading)
      .addCase(aitextQuerySets.fulfilled, (state, action) => {
        state.aiquerystr = action.payload?.query;
        state.aiissuesstr = action.payload?.issues;
        setSucceeded(state);
      })
      .addCase(aitextQuerySets.rejected, setFailed);
  },
});

export default querysetsSlice.reducer;
